export default {
  name: "card4",
  components: {},
  props: {
    cardHeight: {
      type: String,
      default: "102px"
    },
    backgroundColor: String,
    backgroundImage: String,
    bordered: {
      type: Boolean,
      default: true
    },
    title: String,
    titleColor: {
      type: String,
      default: "#b5b5c5"
    },
    titleSize: {
      type: String,
      default: "16px"
    },
    titleWeight: {
      type: Number,
      default: 500
    },
    time: String,
    timeColor: {
      type: String,
      default: "#1bc5bd"
    },
    timeSize: {
      type: String,
      default: "12px"
    },
    timeWeight: {
      type: Number,
      default: 500
    },
    timeBottom: {
      type: String,
      default: "10px"
    },
    description: String,
    descriptionColor: {
      type: String,
      default: "#3f4255"
    },
    descriptionSize: {
      type: String,
      default: "16px"
    },
    descriptionWeight: {
      type: Number,
      default: 600
    },
    descriptionBottom: {
      type: String,
      default: "0px"
    }
  }
};