var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("卡片4")]), _c('Row', {
    staticClass: "n-m-r-0",
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "title": "会议安排",
      "time": "3:30PM - 5:20PM",
      "description": "今天下午记得准时参加3:30开始的会议哦"
    }
  })], 1), _c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "backgroundImage": 'url(' + require('@/assets/dashboard/flower.svg') + ')',
      "title": "最新公告",
      "time": "2021年05月27日",
      "description": "XBoot是很棒的前后端分离一站式快速开发平台"
    }
  })], 1), _c('Col', {
    style: {
      marginBottom: '10px'
    },
    attrs: {
      "sm": 24,
      "md": 24,
      "lg": 8
    }
  }, [_c('card', {
    attrs: {
      "backgroundImage": 'url(' + require('@/assets/dashboard/abstract.svg') + ')',
      "cardHeight": "142px",
      "title": "全新版本发布",
      "time": "XBoot 完整版",
      "timeSize": "14px",
      "timeBottom": "16px",
      "timeColor": "#975ee4",
      "description": "XBoot是很棒的前后端分离一站式快速开发平台，全新版本已发布"
    }
  })], 1)], 1), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };